export enum ENGINES {
  PIQ = 'PIQ' // Promote IQ Ads
}

export const engineAdsPriorityOrder = [ENGINES.PIQ] as const;

export const AD_PLACEMENT_COUNTS = {
  top: {
    min: 4,
    max: 4
  },
  bottom: {
    min: 4,
    max: 40
  }
} as const;

export const TOP_ROW_RETURNED_AD_AMOUNT = 4;
export const BOTTOM_ROW_RETURNED_AD_AMOUNT = 40;

export const TOP_PLACEMENT = 'sponsoredAds.results.top';
export const BOTTOM_PLACEMENT = 'sponsoredAds.results.bottom';
