import { useEffect } from 'react';
import { useInView as defaultUseInView } from 'react-intersection-observer';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import { track } from 'apis/amethyst';
import { evRecommendationImpressionWrapper } from 'events/recommendations';
import ColorSwatchWrapper from 'components/search/ColorSwatches';
import ProductCard from 'components/common/ProductCard';

import css from 'styles/components/search/sponsoredAds.scss';

const SponsoredAdsProduct = ({
  product,
  relatedStyles,
  numSponsoredAds,
  trackSponsoredAdImpressions,
  makeSponsoredAdClick,
  msaImageParams,
  CardBottomSlot,
  isInCarousel,
  isFullMaxWidth,
  includeSwatchRowSpace,
  clickThruUrl,
  microsoftEventUrl,
  useInView = defaultUseInView
}) => {
  const { testId } = useMartyContext();
  const [intersectionRef, inView] = useInView({
    threshold: 0.3 /** 30% was the agreed upon spec from MSFT to fire the view event */,
    triggerOnce: true
  });
  const { viewUrl, index } = product;
  const linkProps = { 'aria-describedby': `sponsoredBanner-${index}` };

  /**
   * Important: "View" events are fired when a customer has the ad in the viewport, via intersection observer
   */
  useEffect(() => {
    if (inView) {
      const recommendationImpression = [
        {
          numberOfRecommendations: numSponsoredAds,
          recommendationType: 'PRODUCT_RECOMMENDATION',
          recommendationSource: 'MICROSOFT',
          widgetType: 'MICROSOFT_TOP_BLOCK'
        }
      ];

      track(() => [evRecommendationImpressionWrapper, { recommendationImpression, products: [product] }]);
      trackSponsoredAdImpressions({ url: viewUrl });
    }
  }, [inView, trackSponsoredAdImpressions, viewUrl, numSponsoredAds, product]);

  if (relatedStyles?.length) {
    return (
      <ColorSwatchWrapper
        intersectionRef={intersectionRef}
        className={cn({
          [css.sponsoredAdsCard]: !isInCarousel,
          [css.displaySixCardTiles]: isFullMaxWidth
        })}
        {...product}
        relatedStyles={relatedStyles}
        msaImageParams={msaImageParams}
        onClick={makeSponsoredAdClick(product, index, microsoftEventUrl)}
        data-test-id={testId('msftWrappedCard')}
        CardBottomSlot={CardBottomSlot}
        linkProps={linkProps}
        clickThruUrl={clickThruUrl}
      />
    );
  }

  return (
    <ProductCard
      intersectionRef={intersectionRef}
      className={cn({
        [css.sponsoredAdsCard]: !isInCarousel,
        [css.displaySixCardTiles]: isFullMaxWidth
      })}
      msaImageParams={msaImageParams}
      onClick={makeSponsoredAdClick(product, index, microsoftEventUrl)}
      {...product}
      data-test-id={testId('msftWrappedCard')}
      CardBottomSlot={CardBottomSlot}
      linkProps={linkProps}
      includeSwatchRowSpace={includeSwatchRowSpace}
      clickThruUrl={clickThruUrl}
    />
  );
};

export default SponsoredAdsProduct;
